import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import logo from "/src/images/iso_color.png";
import Compatibility from "../components/microrec/compatibility";

const CompatibilityPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Compatibility- Custom Surgical" />
      <div style={{ paddingTop: "0.8em" }}>
        <Compatibility />
      </div>
    </Layout>
  );
};

export default CompatibilityPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
